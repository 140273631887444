

/* Colors */
:root {
  --lightblue: #BAE9F5;
  --darkblue: #629AA8;
  --beige: #FDF8E5;
  --orange: #F66F57;
  --dark-grey-text: #888888;
}


body {
  margin: 0;
  font-family: Inter, Inter Placeholder, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.backgroundBeige{
  background-color: var(--beige) !important;
}


.App {
  background-color: #BAE9F5;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
}

.container{
  width: 350px;
  padding-top: 0%;
  text-align: center;
}

.tipsWrapper{
  background-color: var(--beige);
  background-color: var(--beige);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
}

.Swiper{
  min-height: 100vh;
  --swiper-theme-color: #F66F57 !important;
  --swiper-pagination-bullet-inactive-color:#BAE9F5;
  --swiper-pagination-bullet-inactive-opacity:100%;
}

.SwiperSlide{
  min-height: 100vh;
}

.swiper-pagination{
  margin-bottom: 20px;
}

.emoji{
  margin-top: 7vh;
  width: 100px;
}

.skip {
    position: fixed;
    float: left;
    color: #F66F57;
    top: 20px;
    right: 30px;
    z-index: 99;
}

.SwiperSlide > h1{
  color:#F66F57;
  font-size: 38px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 18px;
  padding-right: 18px;
}

.SwiperSlide > h2{
  color:#888888;
  font-size: 26px;
  max-width: 90%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 18px;
  padding-right: 18px;
}

.subtext{
  color:#629AA8;
  font-weight: bold;
  top: 50px;
}

.logo{
  width: 12.5em;
  padding: 1em;
}

.card{
  padding: 20px;
}

h1{
  color:#F66F57;
  font-size: 92px;
}

h2{
  color:#888888;
  font-size: 52px;
}

h3{
  color:#629AA8;
  font-size: 1.8em;
  text-align: center;
  font-weight: normal;
}


.promiseText{
  color:#629AA8;
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;
}

input{
  border: none;
  border-radius: 25px;
  background-color: var(--beige);
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 0px 20px 20px;
  max-width: 300px;
  font-weight: bold;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
  color:#888888;
}

input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color:#888888;
}

button{
  border: none;
  border-radius: 25px;
  width: 100%;
  max-width: 320px;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  margin:0px;
  margin-bottom:10px;
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}

.buttonOrange{
  background-color: #F66F57;
  color: var(--beige);
}

.buttonGrey{
  background-color: lightgrey;
  color: darkgray;
}

.buttonBlue{
  background-color: #629AA8;
  color: #BAE9F5;
}

.buttonIcon{
  width: 55px;
  background-color: var(--beige);
  border-radius: 50px;
  margin: 7px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}

.backButton{
  color:white;
  position:fixed;
  left: 20px;
  top: 20px;
  font-weight: bold;
  font-size: 36px;
}

.backButtonText{
  font-size: 18px;
  float: right;
  margin: 10px;
  z-index: 99999999;
}

.profileButton{
  color:white;
  position:fixed;
  right: 20px;
  top: 0px;
  font-weight: bold;
  font-size: 18px;
}

.profileButtonText{
  font-size: 18px;
  float: right;
  margin: 10px;
  z-index: 99999999;
}

.profileButton .emoji{
       margin-top: 15px; 
      width: 35px;
}

.disabled{
  background-color: lightgrey;
}

.buttonIcon > img{
  width: 25px;
  margin-left: -5px;
  margin-top: 5px;
}

.bubble{
  border-bottom: 3px solid rgba(98, 154, 168, 0.4);
  padding: 5px 25px 5px 25px;
  border-radius: 25px;
  background-color: rgba(255,255,255,0.2);
  font-weight: bold;

}

.emoji{
  padding: 10px;
}

.profileBox{
  background-color: var(--beige);
  padding: 20px;
  width: 250px;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
  margin-bottom: 50px;
  min-height: 85px;
}

.profileBox h1{
  font-size: 20px;
  margin-bottom: 5px;
}

.linkedAccounts h5{
  font-size: 15px;
  margin-bottom: 5px;
  color: #F66F57;
  text-align:left;
}

.userInfo{
  min-height: 85px;
}

/* Clear fix hack */
.profileBox::after {
  content: "";
  display: block;
  clear: both;
}

.profilePicture{
  border-radius: 50px;
  width: 80px;
  float: left;
}

.naming{
  float: right;
  max-width: 150px;
  word-break: break-word;
  text-align: left;
  color:#888888;
}

.profileBox > p{
  text-align: left;
}

.bankBox {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}

.bankOption{
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.bankLogo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  float: left;
  object-fit: cover;
  object-position: center center;
}

.bankLogo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


.installLogo{
  width: 75px;
  height: 75px ;
  background-size:cover;
  border-radius: 50px;
}

.bankOption > h3{
  max-width: 180px;
  font-size: 20px;
  margin: 0px;
  float: left;
  text-align: left;
  margin-left: 10px;
  margin-top: 25px;
}

.bankSearch{
  color:#F66F57;
  padding: 12px 0px 12px 20px;
}

.linkedP{
  font-size: 24px;
  color:#629AA8;
}

.doubleButton{
  width: 350px;
  margin: auto;
}
.doubleButton > button{
  float:left;
  max-width: 45%;
  margin:2%;
}

.bankAccount{
  width: 100%;
  text-align: left;
  height: 60px;
}

.bankAccount img{
  width: 45px;
  height: 45px;
  margin: 7px;
}

.bankAccount h3{
  font-size: 16px;
}

.bankNaming{
  margin: 10px;
  margin-top: 20px;
}

.linkedAccounts .naming{
  text-align: left !important;
  float:left;
}

.shareButton{
  width: 25px;
  color:black;
}

.installPopup{
  background-color: var(--beige);
  color:#F66F57;
  font-weight: bold;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 80%;
  min-width: 250px;
  border-radius: 50px;
}

.installImage{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: inline;
  margin-right: 5px;
}

.installInstruction{
  color:#F66F57;
  font-weight: normal;
}

.installPopup > h1 {
  font-size: 24px;
}

.installPopup, h2{
  font-size: 18px;
}

.installPopup > h3{
  font-weight: bold;
}

.installPopup > ul{
  color:#888888;
  text-align: left;
  list-style: none;
}

.installPopup > img {
  max-width: 80%;
  border-radius: 25px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}

.small{
  font-size: 16px;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-message {
  background-color: var(--beige);
  color:#F66F57 !important;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Add this to your CSS file */
@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulsating {
  animation: pulsate 1s infinite;
}

.buttonBigCircle {
  display: inline-block;
  border: 30px solid #FF9B89;
  border-radius: 50%;
  box-shadow: 0 0 0 25px #F8BDB2;
  width: 70%;
  max-width: 300px;
  aspect-ratio: 1/1;
  padding: 20px;
  background-color: #F66F57;
  margin-top: 50px;
  margin-bottom: 50px;
  color: white;
}
  

.infoPopupBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.infoPopupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.feedbackTextarea{
  width: 80%;
  min-height: 40px;
  padding: 20px;
  margin: 20px;
}

.star{
  font-size:40px;
  margin: 5px;
  margin-bottom: 100px;
  color:#666666;
}

.gold{
  color:#629AA8;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 60%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: var(--beige);
  color: #888888;
}

.SwiperSlide > p{
  max-width: 250px;
  margin: auto;
}

.orange{
  color:#F66F57;
}

.tipInstructies{
  text-align: left;
  max-width: 300px;
  margin: auto;
}

.tipInstructies > li{
  margin: 10px;
  line-height: 18px;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
  max-width: 200px;
  box-shadow: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--lightblue);
  border-radius: 1.3px;
}
input[type=range]::-webkit-slider-thumb {
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--darkblue);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--lightblue);
  border-radius: 1.3px;
}
input[type=range]::-moz-range-thumb {
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--darkblue);
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--lightblue);
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--lightblue);
  border-radius: 2.6px;
}
input[type=range]::-ms-fill-upper {
  background: var(--lightblue);
  border-radius: 2.6px;
}
input[type=range]::-ms-thumb {
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--darkblue);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--lightblue);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--lightblue);
}

.slideGroup{
  max-width: 350px;
  margin: auto;
  margin-bottom: 20px;
  padding-right: 20px;
}

.slideLabel{
  color: var(--darkblue);
  font-weight: bold;
  font-size: 16px;
  background-color: var(--lightblue);
  border-radius: 25px;
  padding: 5px;
  width: 40px;
  float: right;
  margin-top: 27px;
  margin-left: 5px;
}

.slideLabelGrey{
  color: darkgrey;
  background-color: lightgrey;
}